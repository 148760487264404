<template>
  <div class="col-12 g c col-lg-7">
    <div class="card">
      <div class="card-header text-center">
        <h4>
          <i class="fa fa-edit"></i>
          تعديل خط السير
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <h5 for="">عنوان خط السير</h5>
          <input type="text" class="form-control" v-model="title" />
        </div>
        <div class="form-group">
          <h5 for="">التفاصيل</h5>
          <textarea
            class="form-control"
            name=""
            id=""
            v-model="details"
            rows="3"
          ></textarea>
        </div>

        <div class="form-group">
          <h5 for="">القسم</h5>
          <select class="form-control" name="" v-model="section_id" id="">
            <template v-for="section in sections">
              <option :value="section._id" :key="section._id">
                {{ section.title }}
              </option>
            </template>
          </select>
        </div>
        <div class="card card-body">
          <hr />
          <h4>نقاط التوقف</h4>

          <app-collapse>
            <draggable
              v-model="points"
              group="people"
              @start="drag = true"
              @end="drag = false"
            >
              <app-collapse-item
                v-for="(point, index) in points"
                :title="titlea(point.lat, point.long)"
                :key="point.lat"
              >
                <input
                  type="text"
                  v-if="titlea2(point.lat, point.long)"
                  :value="(ranks[titlea2(point.lat, point.long)] = index + 1)"
                />
                <button
                  class="btn btn-sm btn-link text-danger"
                  @click="deletePoint(point.lat)"
                >
                  <i class="fa fa-trash"></i>
                  حذف
                </button>
                <br />
                <p>{{ point.lat + "," + point.long }}</p>
                <div class="mapouter">
                  <div class="gmap_canvas">
                    <iframe
                      style="width: 100%; height: 400px"
                      id="gmap_canvas"
                      :src="`https://maps.google.com/maps?q=${
                        point.lat + ',' + point.long
                      }&t=&z=13&ie=UTF8&iwloc=&output=embed`"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                    ></iframe>
                  </div>
                </div>
              </app-collapse-item>
            </draggable>
          </app-collapse>
        </div>
        <div class="form-group">
          <h5 for="">تحديث نقاط التوقف</h5>
          <select class="form-control" name="" v-model="noupdatelocation" id="">
            <option :value="true">سماح</option>
            <option :value="false">منع</option>
          </select>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-relief-success" @click="add()">
            تعديل خط السير <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import draggable from "vuedraggable";
export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    draggable,
  },
  created() {
    checkPer(this, "groups", "edit");
    var last_point = null,
      g = this;
    g.pasteinterval = setInterval(function () {
      navigator.clipboard.readText().then((text) => {
        if (text.includes(",") && text.includes(".") && !text.includes("/")) {
          if (text != last_point) {
            g.addPoint(text);
            last_point = text;
          }
        }
      });
    }, 500);
    $.post(api + "/admin/groups/group", {
      jwt: g.user.jwt,
      id: window.location.href.split("/edit/")[1],
    })
      .then(function (r) {
        var group = JSON.parse(r).response;
        g.title = group.title;
        g.points = group.points;
        g.details = group.details;
        g.noupdatelocation = group.noupdatelocation;
        g.section_id = group.section_id;
      })
      .catch(function () {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      });
    $.post(api + "/admin/students/list", {
      jwt: g.user.jwt,
      filter: JSON.stringify({
        group_id: window.location.href.split("/edit/")[1],
      }),
    }).then(function (r) {
      g.students = JSON.parse(r).response;
    });
    $.post(api + "/admin/sections/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.sections = JSON.parse(r).response;
    });
  },
  beforeDestroy() {
    clearInterval(this.pasteinterval);
  },
  data() {
    return {
      sections: [],
      section_id: null,
      title: null,
      pasteinterval: null,
      points: [],
      ranks: {},
      details: null,
      students: [],
      noupdatelocation: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
  },
  methods: {
    titlea(lat, long) {
      var g = this,
        r = lat + "," + long;
      g.students.forEach(function (s) {
        if (s.location) {
          if (s.location.lat == lat && s.location.long == long) {
            r = s.name;
          }
        }
      });
      return r;
    },
    titlea2(lat, long) {
      var g = this,
        r = null;
      g.students.forEach(function (s) {
        if (s.location) {
          if (s.location.lat == lat && s.location.long == long) {
            r = s._id;
          }
        }
      });
      return r;
    },
    addPoint(point) {
      if (confirm("هل تريد اضافة الاحداثيات المنسوخة؟")) {
        var g = this;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "تم لصق نقطة توقف",
            icon: "CopyIcon",
            variant: "info",
          },
        });
        var audio = new Audio(
          "https://cdn-static.brmja.com/storage/uploads/files/620511d8d5536534353593.mp3"
        );
        audio.play();
        g.points.push({
          lat: point.split(",")[0].trim(),
          long: point.split(",")[1].trim(),
        });
      }
    },
    deletePoint(lat) {
      var arr = [];
      this.points.forEach((element) => {
        if (element.lat != lat) {
          arr.push(element);
        }
      });
      this.points = arr;
    },
    add() {
      var g = this;
      $.post(api + "/admin/groups/edit", {
        jwt: this.user.jwt,
        id: window.location.href.split("/edit/")[1],
        title: this.title,
        points: this.points,
        details: this.details,
        ranks: this.ranks,
        section_id: this.section_id,
        noupdatelocation: this.noupdatelocation,
      })
        .then(function (e) {
          e = JSON.parse(e);
          if (e.status != 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e.response,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم تعديل خط السير بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.$router.push("/groups/list");
          }
        })
        .catch(function () {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ",
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>